export type ProductForm = {
  product_id: number | null;
  price: number | null;
  price_type: ProductPriceTypes;
  description: string | null;
};

export enum ProductPriceTypes {
  PRICE_PER_PERSON = 'per_person',
  PRICE_FIXED = 'fixed',
  DETERMINED_FUTURE = 'determined_future',
}
